const { smoothAnchorate } = require('smooth-anchorate')
const ReactGA = require('react-ga4').default

// Initialize GA4 with our tracking id
ReactGA.initialize(process.env.GATSBY_GA4_TRACKING_ID)

exports.onRouteUpdate = () => {
  // Smooth scroll setup on every link click created with '<a />' tag
  smoothAnchorate()
}
